<template src="./ShowBuyByTIDaSID.html"></template>

<script>
import Hall from "@/components/hall/Hall";
import ShowDetailText from '@/components/show/ShowBuyByTIDaSID/ShowDetailText.vue';
import {mapGetters} from "vuex";
import store from "@/store";
import {AUTH_REQUEST} from "@/store/actions/auth";
// TODO узнать куда перенести
import { Carousel, Slide } from '@jambonn/vue-concise-carousel'
import '@jambonn/vue-concise-carousel/dist/vue-concise-carousel.css'

import CoolLightBox from 'vue-cool-lightbox'
import 'vue-cool-lightbox/dist/vue-cool-lightbox.min.css'

export default {
  name: "ShowBuyByTIDaSID",

  components: {Hall, ShowDetailText,Carousel, Slide,CoolLightBox},

  data: () => ({

    hello: true,

    width: window.screen.width,
    //carousel
    currentPage: 0,
    isEnableForward: true,
    elPerPage:4,
    //end carousel
    //CoolLightBox
    indexLightBox:null,
    //end CoolLightBox
    isLocalAuth: false,
    show_id:    0,
    event_id:   0,
    culture_id: 0,
    theater:    0,
    showAfishaImg: "https://202702.selcdn.ru/zakaz/d/E39FFEA32C/show/0/a.jpg",
    timeConvert: {
      flag:{
        timeZoneM:{ text: "Утренний", f:false},
        timeZoneD:{ text: "Дневной",  f:false},
        timeZoneE:{ text: "Вечерний", f:false}
      },
      headers:[
        {text: 'Дата',      value: 'timeFull',   align: 'center',  sortable: false}
      ],
      events:[],
      premier:'',
      mainpushka: {startEvent:'',endEvent:''}
    },
    event:{},
    show: {},
    pushka: {},
    iShow:{
      name: "Спектакль",
      age: "0+",
      events:[],
      time: "длительность неопределена",
      loaded: false,
    },
    mobileMore:false,
    mobilSheet: false,
    iPushka:{
      name:             "Имя",
      genre:            "Жанр" ,
      isFree:           false,
      price:            "нет цены",
      maxPrice:         "нет максимальной",
      isPushkinsCard:   false,
      shortDescription: "Короткое описание",
      description:      "Описание",
      persons:          "Актеры и персонал",
      personsRole:      0,
      personsArr:[],
      pushkaGallery:    false,
      galleryList:      [],
      galleryIndex:     null,
      loaded:false,
    },
    view:2,
    options:[],
    hallConfig:{
      base:{
        type: 'user',
        mode: 'event',
        view: 'sale',
        event_id: 0,
      },
      options:{
        zone:true,
        discount:false,
        armour:false,
        puskin:false
      },
      additional:{
        zone_n:0,
        monger_mode: "show_all",
        monger_id:0,
        seat_status_id:0
      }
    }, //hallConfig
    order:{
      email:'',
      phone:'',
      fio:'',
      event_id:   0,
      show_id:    0,
      culture_id: 0,
      link: false,
      payment_type: 0,
      seats:[],
      show_name:'Спектакль не выбран',
      total:{
        price:0,
        count:0,
      },
      date: 'Сегодня',
    },
    defaultLogo: "https://202702.selcdn.ru/zakaz/d/E39FFEA32C/show/0/a.jpg"
  }),

  created() {

    //console.log(this.$route.params);

    if (this.$route.params.tid) {

      //console.log(this.$route.params)
      //console.log('tid:', this.$route.params.tid);
      //return '/buy/:eid';

      // входим под учеткой в этот театр
      const username = "api_user@zakaz.cloud";
      const password = "EubMWqYVyT3W3QFFo6b3JVQCk4wLmwo";
      const isAdmin = false;
      const theater_select = this.$route.params.tid;

      //console.log('before');

      store.dispatch(AUTH_REQUEST, { username, password, theater_select, isAdmin }).then(() => {
        //this.$router.push("/buy/12");

        console.log('ttttt');

        // открываем страницу спектакля
        if (this.$route.params.sid){

          //this.isLocalAuth = true;

          console.log('sid:', this.$route.params.sid);

          this.theater= this.$route.params.tid;
          this.show_id = this.$route.params.sid;

          this.loadEventsByShow();

          //this.hallConfig.base.event_id = this.event_id;

          //console.log(this.event_id);


        }
        else{
          console.log('no sid goto afisha');
        }

      });

      console.log('after');

      //return '/with-params/:td'

    }
    else{
      console.log('нет театра нет входа')
      return '/none'
    }
  },

  computed:{
    ...mapGetters(["getColorZones"]),

    getPricesColors(){


      let price = [];

      let prices = this.event.event.prices.split('/');

      for (let i = 0; i<this.event.price_map.zone_count; i++){

        let t = {};
        t.id = i;
        t.price = prices[i];
        t.color = this.getColorZones[i+1];

        price.push(t);
      }

      //console.log(price)

      return price;
    },

  },

  mounted() {
    this.carouselElPerPage();
    console.log('mount');
  },



  methods:{
    //carousel
    carouselElPerPage(){
      switch(true){
        case (this.width <= 450):
          this.elPerPage = 1;
          break;
        case ((this.width >= 451) && (this.width <= 768)):
          this.elPerPage = 2;
          console.log('мобилка ' + this.width);
        break;
        case ((this.width >= 768) && (this.width <= 990)):
          this.elPerPage = 3;
          console.log('планшет ' + this.width);
          break;
        default:
          this.elPerPage = 4;
          console.log('непонятно ' + this.width);
      }
    },
      handlePageChange(currentPage) {
        this.currentPage = currentPage
        this.isEnableForward = this.$refs.carousel.canAdvanceForward
      },
      onChangeNavigation(direction) {
        this.$refs.carousel.handleNavigation(direction)
      },
    //carousel
    scrollMeTo(refName) {
      let element = this.$refs[refName];
      let top = element.offsetTop;
      window.scrollTo({
        top: top,
        left:0,
        behavior: "smooth"});
    },
    onImgLoadError(e){
      console.log(e);
      console.log('image error');
      this.showAfishaImg = this.defaultLogo;
      this.$forceUpdate();
    },

    onEventClick(event_id){
      console.log(event_id);

      let url = "/b/"+this.$route.params.tid+"/"+event_id;
      this.$router.push(url);

      // this.hallConfig.base.event_id = event_id;
      // this.isLocalAuth = true;
      // this.loadEventAdm();
    },



    urlAfishaAdress(){

      let path = "https://202702.selcdn.ru/zakaz/d/";
      let theater_folder = "E39FFEA32C";
      let theater_afisha = "/show/" + this.iShow.id + "/a.jpg";


      let urlLogo = "";

      switch (this.theater){
      // площадь
      case "1":
        theater_folder = "B6700AADEA";
        break;
      case "2":
        theater_folder = "A1300C78A0";
        break;
      case "3":
        theater_folder = "D81BBC58D4";
        break;
      case "4":
        theater_folder = "F4500EBDC8";
        break;
      case "5":
      case "11":
        theater_folder = "CE223DFBB5";
        break;
      case "6":
        theater_folder = "G0934A7F15";
        break;
      case "7":
        theater_folder = "H37ACA7049";
        break;
      case "8":
        theater_folder = "J853F18DD3";
        break;
      case "12":
        theater_folder = "K93JF8EJW3";
        break;
      case "13":
        theater_folder = "L4GIDT98QM";
        break;
      }

      urlLogo = path + theater_folder + theater_afisha;
      console.log(urlLogo);

      this.defaultLogo = path + theater_folder + "/show/0/a.jpg";

      switch(true){
        case (this.show.info.culture_id===0):
          urlLogo = "https://202702.selcdn.ru/zakaz/d/"+theater_folder+"/show/0/a.jpg"
          this.showAfishaImg = urlLogo;
          break;
        default:
          this.showAfishaImg = urlLogo;
          this.iPushka.galleryList.unshift(urlLogo);
      }
      return;
    },
    eventTableConvert(dataEvents){
      dataEvents.data.forEach((item)=>{
        let hourConv = item.timedate_h.time.substr(0, 2);
        let timeZoneM = '';
        let timeZoneD = '';
        let timeZoneE = '';
        let timeFull = item.timedate_h.day + ' ' + item.timedate_h.month_r + ' (' + item.timedate_h.day_name_s + ')';
        let inApple = false;
        let startPrice =  item.prices.split('/')[0];

        switch(true){
          case (hourConv < 12):
            timeZoneM = {"timeZone":"M","id":item.id, "show_id":item.show_id, "month_r":item.timedate_h.month_r, "time":item.timedate_h.time, "day":item.timedate_h.day, "day_name_s":item.timedate_h.day_name_s, "actual":item.timedate_h.actual, "prices":startPrice, "status":item.status, "pushka":item.pushka};
            this.timeConvert.flag.timeZoneM.f = true;
            break;
          case (hourConv >= 12 && hourConv < 17):
            timeZoneD = {"timeZone":"D","id":item.id, "show_id":item.show_id, "month_r":item.timedate_h.month_r, "time":item.timedate_h.time, "day":item.timedate_h.day, "day_name_s":item.timedate_h.day_name_s, "actual":item.timedate_h.actual, "prices":startPrice, "status":item.status, "pushka":item.pushka};
            this.timeConvert.flag.timeZoneD.f = true;
            break;
          case (hourConv >= 17 && hourConv < 24):
            timeZoneE = {"timeZone":"E","id":item.id, "show_id":item.show_id, "month_r":item.timedate_h.month_r, "time":item.timedate_h.time, "day":item.timedate_h.day, "day_name_s":item.timedate_h.day_name_s, "actual":item.timedate_h.actual, "prices":startPrice, "status":item.status, "pushka":item.pushka};
            this.timeConvert.flag.timeZoneE.f = true;
            break;
          default:
        }
        this.timeConvert.events.forEach((item)=>{
          if(timeFull === item.timeFull){
            console.log("В яблочко")
            if(timeZoneM){
              item.timeZoneM = timeZoneM;
            }
            if(timeZoneD){
              item.timeZoneD = timeZoneD;
            }
            if(timeZoneE){
              item.timeZoneE = timeZoneE;
            }
            inApple = true;
          }
        })
        if(inApple){return;}
        let eventTable = {
          timeFull:timeFull,
          timeZoneM: timeZoneM,
          timeZoneD:timeZoneD,
          timeZoneE:  timeZoneE
        }
        this.timeConvert.events.push(eventTable);
      })
      this.timeConvert.headers = [{text: 'Дата', value: 'timeFull', align: 'center', sortable: false}];
      for (const [key, value] of Object.entries(this.timeConvert.flag)) {
        if (value.f){
          this.timeConvert.headers.push({text: value.text, value: key, align: 'center', sortable: false})
        }
        else{
          // тот еще способ сократить количество столбцов в таблие ><
          this.timeConvert.events.forEach(event=>{
            delete event[key];
          })
        }
      }
    },
    loadEventsByShow(){
      this.iShow.loaded = false;
      //TODO if isPushka:0 => show:undefined;
      //если выставить isPushka 0 - мероприятия типа s/1/1 загрузятся,
      //но перестанут работать нормальные мероприятия
      let pr2 = this.$store.state.api.findEventsByShowStatusPushka(this.show_id, 32, 1);
      pr2.then( data =>{
        console.log(data);
        this.show = data['0'];
        if (this.show.info.culture_id>0){
          this.culture_id = this.show.info.culture_id;
        }
        this.iShow.id = parseInt(data['0'].info.id);
        this.iShow.name = data['0'].info.name;
        this.iShow.age =  data['0'].info.age;
        this.iShow.time = data['0'].info.time;

        this.iShow.events = data['0'].data;

        this.iShow.loaded = true;

        this.loadPushkaByShow();
        this.eventTableConvert(data['0']);
        this.urlAfishaAdress();
      })
    },
    personsCount(data){
      var roleCount = [{"roleRUS":"Актеры","role":"actor","personCount":0,"person":[]}];
      data.forEach((item)=>{
        if(roleCount.find(el => el.role != item.role)){
          let RoleItem;
          switch (item.role){
            case "director":
              RoleItem = "Режисер";
            break;
            case "choreographer":
              RoleItem = "Хареограф";
            break;
            case "artisticDirector":
              RoleItem = "Художественный руководитель";
            break;
            default:
            //  TODO посмотреть как заполняются поля, что бы получить Русские версии role
              RoleItem = item.role;
          }
          roleCount.unshift({"roleRUS":RoleItem,"role":item.role,"personCount":0,"person":[]})
        }
        let findRoleEl = roleCount.find(el=>el.role == item.role);
        findRoleEl.person.push(item.name);
      })
      roleCount.forEach((item,index,object)=>{
        if(item.person.length === 0){
          object.splice(index, 1);
        }else{
          item.personCount = item.person.length;
        }
      })
      this.iPushka.personsArr = roleCount;
      this.iPushka.personsRole = roleCount.length;
    },
    loadPushkaByShow(){

      this.iPushka.loaded = false;

      let pr2 = this.$store.state.api.loadPushkaPro(this.culture_id);

      pr2.then( data =>{

        console.log(data);

        this.iPushka = {

          name:             data['events'][0].name,
          genre:            data['events'][0].extraFields.genre,
          isFree:           data['events'][0].isFree,
          price:            data['events'][0].price,
          maxPrice:         data['events'][0].maxPrice,
          isPushkinsCard:   data['events'][0].isPushkinsCard,
          shortDescription: data['events'][0].shortDescription,
          description:      data['events'][0].description,
          persons:          data['events'][0].extraFields.persons,

          pushkaGallery:    false,
          galleryList:      [],
          galleryIndex:     null,

          loaded:false,
        }

        this.iPushka.galleryList.push("https://pro.culture.ru/uploads/"+data['events']['0'].image.name);

        data['events']['0'].gallery.forEach(item=>{
          this.iPushka.galleryList.push('https://pro.culture.ru/uploads/'+item.name);
        })

        if(data['events']['0'].gallery.length > 0){
          this.iPushka.pushkaGallery = true;
        }
        this.personsCount(data['events'][0].extraFields.persons);
        this.iPushka.loaded = true;
      })

    },



    clear_order(){
      this.order = {
        event_id:   this.event_id,
        show_id:    this.show_id,
        culture_id: this.culture_id,
        payment_type: 0,
        seats:[],
        total:{
          price:0,
          count:0,
        }
      }
    },

    // eslint-disable-next-line no-unused-vars
    onSeatClick(seat_id, status){

      // console.log(seat_id, status);
      //
      // let t = this.order.seats.findIndex(seat=>(seat===seat_id));
      // console.log(t);

      let tt = this.$refs.hall.getSelected();

      let ids = [];
      let seats = [];

      let prices = this.event.event.prices.split('/');

      let t = {};
      let total = {
        count:0,
        price:0
      };

      tt.forEach(seat=>{
        //console.log(seat);
        ids.push(seat.base.id);

        t = {
          id:seat.base.id,
          zone: seat.additional.price_zone,
          price: prices[seat.additional.price_zone-1],
        }

        total.count++;
        total.price +=  parseInt(t.price)

        seats.push(t);

      })

      console.log(seats, total);


      this.order.seats = ids;
      this.order.total = total;

      // отправлять эти данные в store
      //this.$store.dispatch('updateOrder', this.order);

      this.$store.commit('updateOrder', this.order)


    },

    onReadyHall(){
      console.log('hall is ready');
      this.loadEventAdm();
    },

    onLoadedHall(event){
      console.log(event);
      console.log(event.data.event.date.actual);

      this.event = event.data;
      this.eventloaded = true;

      this.order.event_id   = this.event.event.id;
      this.order.culture_id = this.event.show.culture_id;
      this.order.show_id    = this.event.show.id;
      this.order.show_name  = this.event.show.name;
      this.order.date       = this.event.event.timedate;



      let data = {
        view: this.hallConfig.base.view,
        options: this.hallConfig.options,
        additional: this.hallConfig.additional,
      }

      this.$refs.hall.updateMode(data);

      this.$nextTick(() => {
        this.$refs.hall.full_redraw();
        this.$nextTick(() => {
          this.$refs.hall.full_redraw();
        })
      })

    },

    loadEventAdm(){
      //console.log('cl');
      this.selected_table = [];
      this.flagSetMongerArmour = false;
      let t = this.prepareDataHall();
      console.log(t);
      this.$refs.hall.prepareHall(t);
    },

    updateMode: function () {
      // выбрали уполномоченых - сбросили на бронь на ПОСТАВИТЬ
      //this.EnvoyModeSetArmour = true
      this.$nextTick(() => {
        // let data = {
        //   view: this.getViewById(this.view),
        //   options: this.getOptions(this.options),
        //   additional: this.getAdditional(this.additional),
        // }
        //let t = this.prepareDataHall();
        //console.log(data);
        //this.$refs.hall.updateMode(t);
        this.$nextTick(() => {
          this.$refs.hall.full_redraw();
          this.$nextTick(() => {
            this.$refs.hall.full_redraw();
          })
        })
      })
    },

    prepareDataHall(){
      let data = {
        type: this.hallConfig.base.type,
        mode: this.hallConfig.base.mode,
        view: this.hallConfig.base.view,
        options: this.hallConfig.options,
        additional: this.hallConfig.additional,
        event_id: this.hallConfig.base.event_id,
        scale:0.5
      }
      //console.log(data);
      return data;
    },
    getSelectedSeats(){
      //this.$refs.hall.getSelected(true);
    },
    clearSelected(){
      //this.$refs.hall.clearSelected();
    },
    doOrder(){
      console.log('order');
      let post_data = {
        base:{
          email:"mail",
          total:"700",
          user:1,
          mode:"kassa"
        },
        orders:[
          {
            event:1,
            price:700,
            seats:[
              7148, 7157
            ]
          }
        ]
      }
      console.log(post_data);
      let pr2 = this.$store.state.api.addOrder(post_data);
      pr2.then( data =>{
        console.log(data);
        // resp : id only
        //
        // this.layouts = data;
        // this.layouts_ready = true;
      })

    },

  }
}



</script>

<style scoped>
  @media(max-width: 768px){
    .left-aside{
      width: 100%;
      flex: 0 0 100%;
    }
    .right-column{
      max-width: 100%;
      width: 100%;
      flex: 0 0 100%;
    }
    .v-data-table > .v-data-table__wrapper .v-data-table__mobile-row{
      min-height: auto !important;
    }
    .v-data-table__mobile-table-row .v-data-table__mobile-row:last-child .v-data-table__mobile-row__cell{
      margin-bottom: 0 !important;
    }
  }
  @media(min-width: 769px){
    .left-aside{
      max-width: 280px;
    }
    .right-column{
      max-width: calc(100% - 280px);
    }
    .fullWidthColumn{
      width: 100%;
      max-width: 100%;
      flex: 0 0 100%;
    }
  }
  @media(min-width: 990px){
    .left-aside{
      max-width: 400px;
    }
    .right-column{
      max-width: calc(100% - 400px);
    }
    .fullWidthColumn{
      width: 100%;
      max-width: 100%;
      flex: 0 0 100%;
    }
  }




/*  */
/*  */
/*  */
  .carousel {
    background: #EEE;
  }

  .carousel-cell {
    width: 28%;
    height: 200px;
    margin-right: 10px;
    background: #8C8;
    border-radius: 5px;
    counter-increment: carousel-cell;
  }

  .carousel-cell.is-selected {
    background: #ED2;
  }

  /* cell number */
  .carousel-cell:before {
    display: block;
    text-align: center;
    content: counter(carousel-cell);
    line-height: 200px;
    font-size: 80px;
    color: white;
  }
  .carouselGallery img{
    max-width: 100%;
    max-height: 200px;
    box-shadow: 0 0 10px 0 #000;
    border-radius: 5px;
  }
  .carouselGallery .carousel-arrow{
    width: 32px;
    height:32px;
    text-align: center;
    background: #2196F3 !important;
  }
  .carousel-arrow-prev,
  .carousel-arrow-next{
    position: absolute;
    top:40%;
    transform:translate(0,-50%);
  }
  .carousel-arrow-prev{
    left:0;
  }
  .carousel-arrow-next{
    right: 0;
  }
  .carousel-arrow[disabled="disabled"]{
    filter: opacity(0.5);
  }
  .v-data-table > .v-data-table__wrapper > table > tbody > tr > td{
    padding: 0;
  }
  @media(max-width: 768px){
    .carousel-arrow-prev,
    .carousel-arrow-next{
      position: absolute;
      top:30%;
      transform:translate(0,-50%);
    }
    .carousel-arrow-prev{
      left:-20px;
    }
    .carousel-arrow-next{
      right:-20px;
    }

  }
</style>

